import React from "react";
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import {Button, FormControl, Form, Modal} from 'react-bootstrap';
import { createDemo, listCountries } from "../../request"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class TrialRequestDemoForm extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            firstName: "",
            lastName: "",
            company: "",
            email: "",
            phoneNumber: "",
            country: null,
            countryCode: "NL",
            countries: [],
            honeyPot: null,
            hasFirstNameError: false,
            hasLastNameError: false,
            hasCompanyError: false,
            hasEmailError: false,
            hasPhoneNumberError: false,
            success: false,
            isProcessing: false,
            showModal: false,
            fieldsError: false,
            serverError: false,
            finished: false
        }
    }

    componentDidMount(){
        //Get a list of countries
        listCountries().then((results) => {

            let countries = [];

            let country = results.data.countries.find((c) => {
                return c.alpha2_code === this.state.countryCode;
            });

            results.data.countries.forEach((c) => {
                if (c.calling_codes && c.calling_codes.length === 1  && c.calling_codes[0] !== "" /* && !this.checkIfInList(countries, c)*/) {
                    c.calling_code = c.calling_codes[0];
                    countries.push(c);
                }
            });

            this.setState({
                countries: countries,
                country: country
            });

        });
    }

    fetchDefaultCountry = () => {

        let country = this.state.countries.find((c) => {
            return c.alpha2_code === this.state.countryCode;
        });

        return country;
    }

    handleCountryChange = (e) => {
        e.persist();

        let country = this.state.countries.find((c) => {
            return c.alpha2_code === e.target.value
        });

        this.setState({
            country: country,
            countryCode: country.alpha2_code
        });
    }

    handleInputChange = (e) => {
        if (e && e.target && e.target.name) {
            this.setState({
                [e.target.name]: e.target.value
            });
        }

        this.setState({
            hasFirstNameError: false,
            hasLastNameError: false,
            hasCompanyError: false,
            hasEmailError: false,
            hasPhoneNumberError: false,
        });
    }

    handleHoneyPotChange = event => {
        const target = event.target;
        const value = target.value;

        this.setState({
            honeyPot: value
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isProcessing: true,
            finished: false,
            hasFirstNameError: false,
            hasLastNameError: false,
            hasCompanyError: false,
            hasEmailError: false,
            hasPhoneNumberError: false,
            fieldsError: false,
            serverError: false
        });

        if (!this.state.firstName) {

            this.setState({
                isProcessing: false,
                showModal: true,
                fieldsError: true,
                hasFirstNameError: true
            });


        } else if (!this.state.lastName) {

            this.setState({
                isProcessing: false,
                showModal: true,
                fieldsError: true,
                hasLastNameError: true
            });


        } else if (!this.state.company) {

            this.setState({
                isProcessing: false,
                showModal: true,
                fieldsError: true,
                hasCompanyError: true
            });


        } else if (!this.state.email && !this.validateEmail(this.state.email)) {

            this.setState({
                isProcessing: false,
                showModal: true,
                fieldsError: true,
                hasEmailError: true
            });

        } else if (!this.state.phoneNumber) {

            this.setState({
                isProcessing: false,
                showModal: true,
                fieldsError: true,
                hasPhoneNumberError: true
            });

        } else {

            let data = {
                requestDemo: true,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                country: this.state.countryCode,
                company: this.state.company,
                phone: `+${this.state.country.calling_code}${this.state.phoneNumber}`
            };

            createDemo({email: this.state.email, data: data}).then(() => {

                this.setState({
                    isProcessing: false,
                    showModal: true,
                    firstName: "",
                    lastName: "",
                    company: "",
                    email: "",
                    phoneNumber: "",
                    country: this.fetchDefaultCountry(),
                    countryCode: "NL",
                    hasFirstNameError: false,
                    hasLastNameError: false,
                    hasCompanyError: false,
                    hasEmailError: false,
                    hasPhoneNumberError: false,
                    finished: true,
                    serverError: false
                });

                window.dataLayer.push('event', 'conversion', {'send_to': 'AW-970402719/wCTdCNTc0ZEYEJ_X3M4D'});

            }).catch((e) => {

                this.setState({
                    isProcessing: false,
                    showModal: true,
                    finished: false,
                    serverError: true
                });

            });

        }
    }

    closeModal = () => {
        this.setState({
            showModal: false
        });
    }

    validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    render() {
        return (
            <div className={`trial-request-demo-form`}>
                <div className={`title`}><FormattedMessage id="components.trialRequestDemoForm.title" /></div>
                <div className={`text`} dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({ id: "components.trialRequestDemoForm.text" }) }}></div>
                <div className={`form`}>
                    <Form className={`form-area`} onSubmit={this.handleSubmit}>

                        <div className={`form-row`}>
                            <div className="form-field-group col-md-6">
                                <Form.Label className="form-label"><FormattedMessage id="components.trialRequestDemoForm.firstName.label" /></Form.Label>
                                <FormControl type="text" value={this.state.firstName} name={`firstName`} className="form-field" onChange={this.handleInputChange} />
                                {this.state.hasFirstNameError === true &&
                                  <>
                                      <div className={`feedback-text error`}><FormattedMessage id="components.trialRequestDemoForm.firstName.error" /></div>
                                      <div className={`feedback-icon error`}><FontAwesomeIcon size={`lg`} icon="exclamation-circle" /></div>
                                  </>
                                }
                            </div>

                            <div className="form-field-group col-md-6">
                                <Form.Label className="form-label"><FormattedMessage id="components.trialRequestDemoForm.lastName.label" /></Form.Label>
                                <FormControl type="text" value={this.state.lastName} name={`lastName`} className="form-field" onChange={this.handleInputChange} />
                                {this.state.hasLastNameError === true &&
                                  <>
                                      <div className={`feedback-text error`}><FormattedMessage id="components.trialRequestDemoForm.lastName.error" /></div>
                                      <div className={`feedback-icon error`}><FontAwesomeIcon size={`lg`} icon="exclamation-circle" /></div>
                                  </>
                                }
                            </div>
                        </div>

                        <div className="form-field-group">
                            <Form.Label className="form-label"><FormattedMessage id="components.trialRequestDemoForm.company.label" /></Form.Label>
                            <FormControl type="text" value={this.state.company} name={`company`} className="form-field" onChange={this.handleInputChange} />
                            {this.state.hasCompanyError === true &&
                              <>
                                  <div className={`feedback-text error`}><FormattedMessage id="components.trialRequestDemoForm.company.error" /></div>
                                  <div className={`feedback-icon error`}><FontAwesomeIcon size={`lg`} icon="exclamation-circle" /></div>
                              </>
                            }
                        </div>

                        <div className="form-field-group">
                            <Form.Label className="form-label"><FormattedMessage id="components.trialRequestDemoForm.email.label" /></Form.Label>
                            <FormControl type="text" value={this.state.email} name={`email`} className="form-field" onChange={this.handleInputChange} />
                            {this.state.hasEmailError === true &&
                              <>
                                  <div className={`feedback-text error`}><FormattedMessage id="components.trialRequestDemoForm.email.error" /></div>
                                  <div className={`feedback-icon error`}><FontAwesomeIcon size={`lg`} icon="exclamation-circle" /></div>
                              </>
                            }

                        </div>

                        <div className="form-field-group">
                            <Form.Label className="form-label"><FormattedMessage id="components.trialRequestDemoForm.phoneNumber.label" /></Form.Label>
                            <div className={`phone-selector`}>
                                <div className={`select-container country-selector`}>
                                    {this.state.country &&
                                      <div className={`country-flag`}><img alt={this.state.country.name} src={this.state.country.flag} /></div>
                                    }
                                    {this.state.countries &&
                                      <FormControl as={`select`} value={this.state.countryCode} name={`country`} className="form-field" onChange={this.handleCountryChange}>
                                          {this.state.countries.map((country, i) => {
                                              return (
                                                <option key={i} value={country.alpha2_code}>(+{country.calling_code}) {country.name}</option>
                                              )
                                          })}
                                      </FormControl>
                                    }
                                </div>
                                <FormControl type="text" value={this.state.phoneNumber} name={`phoneNumber`} className="form-field phone-input" onChange={this.handleInputChange} />
                                {this.state.hasPhoneNumberError === true &&
                                  <>
                                      <div className={`feedback-text error`}><FormattedMessage id="components.trialRequestDemoForm.phoneNumber.error" /></div>
                                      <div className={`feedback-icon error`}><FontAwesomeIcon size={`lg`} icon="exclamation-circle" /></div>
                                  </>
                                }
                            </div>
                        </div>

                        <div style={{position: "absolute", left: "-9999px", overflow: "hidden"}} aria-hidden="true">
                            <input className="form-field" placeholder={this.props.intl.formatMessage({ id: "components.trialRequestDemoForm.email.placeholder" })} type="text" onChange={this.handleHoneyPotChange} />
                        </div>

                        <Button className={`form-button`} variant="secondary" type="submit" block disabled={this.state.isProcessing}><FormattedMessage id="components.trialRequestDemoForm.button" /></Button>
                    </Form>

                    <Modal show={this.state.showModal} onHide={this.closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.fieldsError === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="exclamation-triangle" />
                                    <FormattedMessage id="components.trialRequestDemoForm.alerts.titles.warning" />
                                </>
                                }
                                {this.state.serverError === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="exclamation-circle" />
                                    <FormattedMessage id="components.trialRequestDemoForm.alerts.titles.error" />
                                </>
                                }
                                {this.state.finished === true &&
                                <>
                                    <FontAwesomeIcon size={`lg`} style={{marginRight: "15px"}} icon="check-circle" />
                                    <FormattedMessage id="components.trialRequestDemoForm.alerts.titles.success" />
                                </>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={`text-center m-3`}>
                                {this.state.fieldsError === true &&
                                <FormattedMessage id="components.trialRequestDemoForm.alerts.error" />
                                }
                                {this.state.serverError === true &&
                                <FormattedMessage id="components.trialRequestDemoForm.alerts.serverError" />
                                }
                                {this.state.finished === true &&
                                <FormattedMessage id="components.trialRequestDemoForm.alerts.success" />
                                }
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            </div>
        )
    }
}

export default injectIntl(TrialRequestDemoForm)
